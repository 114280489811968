import Vue from 'vue'
import { 
    CToggler,
    CWrapper,
    CHeader, 
    CHeaderBrand, 
    CHeaderNav, 
    CHeaderNavItem, 
    CHeaderNavLink,
    CSidebar,
    CSidebarBrand,
    CSidebarNav,
    CSidebarMinimizer,
    CSidebarNavItem,
    CSidebarNavTitle,
    CFooter,
    CDropdown,
    CDropdownHeader,
    CDropdownItem,
    CSubheader,
    CBreadcrumbRouter
} from '@coreui/vue';
import '@coreui/coreui/dist/css/coreui.min.css'

Vue.component('CSubheader', CSubheader);
Vue.component('CBreadcrumbRouter', CBreadcrumbRouter);
Vue.component('CToggler', CToggler);
Vue.component('CWrapper', CWrapper);
Vue.component('CHeader', CHeader);
Vue.component('CHeaderBrand', CHeaderBrand);
Vue.component('CHeaderNav', CHeaderNav);
Vue.component('CHeaderNavItem', CHeaderNavItem);
Vue.component('CHeaderNavLink', CHeaderNavLink);
Vue.component('CSidebar', CSidebar);
Vue.component('CSidebarBrand', CSidebarBrand);
Vue.component('CSidebarNav', CSidebarNav);
Vue.component('CSidebarMinimizer', CSidebarMinimizer);
Vue.component('CSidebarNavItem', CSidebarNavItem);
Vue.component('CSidebarNavTitle', CSidebarNavTitle);
Vue.component('CFooter', CFooter);
Vue.component('CDropdown', CDropdown);
Vue.component('CDropdownHeader', CDropdownHeader);
Vue.component('CDropdownItem', CDropdownItem);