export default [
    {
    path: "/",
    name: "Home",
    component: () => import("../container/Container.vue"),
    redirect: "dashboard",
    children: [
        {
            path: "dashboard",
            name: "Dashboard",
            component: () => import("../pages/dashboard.vue"),
        },
        {
            path: "tn-scraper",
            name: "TN Scraper",
            component: () => import("../pages/tnscraper.vue"),
        },
        {
            path: "gmail-tn-scraper",
            name: "Gmail TN Scraper",
            component: () => import("../pages/gmailtnscraper.vue"),
        },
        {
            path: "fba-shipments-tracking",
            name: "FBA Shipments Tracking",
            component: () => import("../pages/fbashipmentstracking.vue"),
        },
        {
            path: "upc-generator-old",
            name: "UPC Generator Old",
            component: () => import("../pages/upcgenerator-old.vue"),
        },
        {
            path: "/upc-generator",
            name: "UPC Generator",
            redirect: "/upc-generator/result",
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: "result",
                    name: "UPC Generator Result",
                    component: () => import("../pages/upcgenerator.vue")
                },
                {
                    path: "config",
                    name: "UPC Generator Config",
                    component: () => import("../pages/upcconfigs.vue")
                }
            ]
        },
        {
            path: "excel-parsers",
            name: "Excel Parsers",
            redirect: "/excel-parsers/result",
            component: {
                render(c) { return c('router-view') }
            },
            children: [
                {
                    path: "result",
                    name: "Excel Parser Result",
                    component: () => import("../pages/excelparsers.vue")
                },
                {
                    path: "brands",
                    name: "Excel Parser Brands",
                    component: () => import("../pages/excelparserbrands.vue")
                }
            ]
        },
        {
            path: "cogs",
            name: "DC COGS",
            component: () => import("../pages/btcost.vue"),
        }
    ],
    meta: { 
        requiresAuth: true 
    }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("../pages/login.vue")
    },
    {
        path: "/signup",
        name: "Signup",
        component: () => import("../pages/signup.vue")
    },
    {
        path: "*",
        name: "Page Not Found",
        component: () => import("../pages/pagenotfound.vue")
    }
]