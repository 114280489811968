import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from './routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	let auth = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
	if (to.matched.some((record) => record.meta.requiresAuth)) {
		if (!auth){
			next({ path: '/login', params: { nextUrl: to.fullPath } })
		} else {
			next()
		}
	} else {
		if(auth){
			next({path: from.fullPath})
		}else{
			next();
		}
	}
});

router.afterEach((to) => {
	document.title = to.name || 'SM Tools';
});

export default router